import { getAuthInfo } from '.';
import { throwEndpointError } from '../../../utils';

export default async function fetchDocumentsListing() {
  const { jwt } = await getAuthInfo();
  const res = await fetch(
    `${process.env.GATSBY_AMWELL_API_ENDPOINT}/account/documents`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization: jwt,
      }),
    }
  ).catch((err) =>
    throwEndpointError(err, res, 'Error fetching documents information', false)
  );

  if (!res.ok) {
    console.log('Error fetching documents:', res);
    throwEndpointError({}, res, 'Error fetching documents information', false);
    return;
  }

  const data = await res.json();
  console.log('Documents Information', JSON.stringify(data));
  // eslint-disable-next-line consistent-return
  return data;
}
