import React from 'react';
import { Helmet } from 'react-helmet';
import './scss/Documents.scss';
import { addAuthentication } from '../../../components/addAuthentication';
import {
  DashboardPageHeading,
} from '../../../components/dashboard';
import DashboardDocumentsList from '../../../components/dashboard/DashboardDocumentsList/DashboardDocumentsList';
import { DashboardLayout } from '../../../components/layout';

function DocumentsPage() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Amwell Private Practice | Dashboard - My Documents</title>
      </Helmet>
      <DashboardLayout>
        <div className="DocumentsPage">
          <DashboardDocumentsPageHeading />
          <div className="DocumentsPage-container">
            <DashboardDocumentsList />
          </div>
        </div>
      </DashboardLayout>
    </>
  );
}

const DashboardDocumentsPageHeading = () => (
  <DashboardPageHeading
    heading={'My Documents'}
    links={[{ label: 'Document Listing', to: '/dashboard/documents' }]}
  />
);

export default addAuthentication(DocumentsPage);
