import React, { useContext, useState } from 'react';
import axios from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import { saveAs } from 'file-saver';
import { LoadingSpinner } from '../../misc';
import { DispatchContext, StateContext } from '../../../lib/providers';
import { Amwell } from '../../../lib/services';
import { APP_SET_USER_DOCUMENTS_DATA } from '../../../lib/events/app/types';
import './DashboardDocumentsList.scss';

export default function DashboardDocumentsList() {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const { documents } = state;
  const [buttonText, setButtonText] = useState('Download');
  const [isDisabled, setIsDisabled] = useState(false);

  /**
   * Format the returned date string to MM/DD/YYYY for display on the dashboard
   *
   * @param dateString
   * @return {`${string}/${string}/${number}`}
   */
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() returns month from 0-11
    const day = String(date.getUTCDate()).padStart(2, '0');
    const year = date.getUTCFullYear();

    return `${month}/${day}/${year}`;
  };

  const GenerateButtonId = (documentsId) => `button_${documentsId}`;

  /**
   * Download the requested document
   *
   * @param event
   * @return {boolean}
   * @constructor
   */
  const DownloadDocument = async (event) => {
    event.preventDefault();
    const documentId = event.target.getAttribute('data-documentid');
    const documentName = event.target.getAttribute('data-documentname').replace(' ','_');
    setButtonText('Downloading...');
    setIsDisabled(true);
    const DOWNLOAD_ENDPOINT = `${process.env.GATSBY_AMWELL_API_ENDPOINT}/download/${documentId}`;

    axios
      .get(DOWNLOAD_ENDPOINT, {
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf',
        },
      })
      .then(async (response) => {
        await saveAs(response.data, `${documentName}.pdf`);
        setButtonText('Download');
        setIsDisabled(false);
      });
    return false;
  };

  /**
   * Generate the My Documents listing for the dashboard
   *
   * @return {Element}
   * @constructor
   */
  const CreateDocumentsList = () => (
    <div className="DashboardDocumentsTable">
      <table>
        <thead>
          <tr>
            <th>File Name</th>
            <th>Date</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{documents.Name}</td>
            <td>{formatDate(documents.CreatedDate)}</td>
            <td>
              <button
                id={GenerateButtonId(documents.Id)}
                data-documentid={documents.Id}
                data-documentname={documents.Name}
                onClick={DownloadDocument}
                disabled={isDisabled}
              >
                {buttonText}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  if (!documents) {
    (async () => {
      const documentsData = await Amwell.fetchDocumentsListing();
      dispatch({
        type: APP_SET_USER_DOCUMENTS_DATA,
        payload: { documents: documentsData[0] },
      });
    })();
  } else {
    console.log('Documents Found:', documents);
  }

  const DisplayDocuments = () => (
    <>
      <CreateDocumentsList />
    </>
  );

  return (
    <div className="DashboardDocumentsList">
      {!documents ? <LoadingSpinner /> : <DisplayDocuments />}
    </div>
  );
}
